import React from 'react';
import { SwiperOptions } from 'swiper/core';

import { Image } from 'common/Image';

export enum SliderVariant {
  PRODUCTS = 'products',
  ARTICLES = 'articles',
  VIDEO = 'video',
  BANNER = 'banner',
}

export interface SliderProps extends SwiperOptions {
  slides:
    | {
        id: string | number;
        component: React.ReactNode;
      }[];
  variant: SliderVariant;
  customClass?: string;
  sliderId: string;
  setIsChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface SlidePropsType {
  data: {
    title: string;
  };
  image: Image;
}
