import { CardType } from 'components/Card';

export enum CarouselVariant {
  LIGHT = 'light',
  DARK = 'dark',
  BLUE = 'blue',
}

export type CarouselType = {
  title: string;
  variant: CarouselVariant;
  button?: ButtonProps;
};

export type CarouselProps = {
  carousel: CarouselType;
  carouselVariant: SliderVariant;
  carouselItems: CardType[];
  className?: string;
  sliderId?: string;
};

type CarouselColors = 'backgroundColor' | 'titleColor';

export type CarouselMappedVariantType = {
  [variant in CarouselVariant]: Record<CarouselColors, UiSettings.ColorPickerType>;
};
