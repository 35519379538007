import React from 'react';
import classNames from 'classnames';

import Typography, { TypographyProps } from 'common/Typography';

import { ChallengeShape } from './ChallengeShape';

import { BadgeProps } from './models.d';

import './Badge.scss';

const Badge = ({ variant = 'solid', content, className, ...customTypographyProps }: BadgeProps) => {
  const badgeClasses = classNames(
    'badge',
    {
      [`badge--${variant}`]: variant,
    },
    className
  );

  const defaultTypographyProps: TypographyProps = {
    size: 16,
    color: 'white',
    padding: { top: 'sm', right: 'sm', bottom: 'sm', left: 'sm' },
    align: 'center',
    weight: 'semi-bold',
  };

  return content ? (
    <Typography
      className={badgeClasses}
      data-testid="badge-item"
      {...defaultTypographyProps}
      {...customTypographyProps}
    >
      {variant === 'challenge' ? <ChallengeShape className="badge__icon" /> : null}
      <span>{content}</span>
    </Typography>
  ) : null;
};

export default Badge;
