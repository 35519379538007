import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { breakpoints as breakpointsValues } from 'shared/static';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import { toggleState } from 'utils/toggleState';

import { SliderProps, SliderVariant } from './models.d';

import 'swiper/swiper-bundle.css';
import './Slider.scss';

const { VIDEO, BANNER } = SliderVariant;

SwiperCore.use([Pagination, Autoplay, Navigation]);

const Slider: FC<SliderProps> = ({ slides, variant, customClass, setIsChanged, sliderId }) => {
  const sliderClasses = classNames('slider', customClass, {
    [`slider--${variant}`]: variant,
  });

  const [displayPagination, setDisplayPagination] = useState(true);

  const paginationClassNames = classNames([
    'swiper__box-pagination',
    `swiper__box-pagination--${variant}`,
    {
      [`swiper__box-pagination--hidden`]: !displayPagination,
    },
  ]);

  const isVideoSlider = variant === VIDEO;

  const swiperParams = () => {
    if (variant === VIDEO) {
      return {
        slidesPerView: 1,
        loop: false,
        pagination: false,
        navigation: true,
        breakpoints: {
          [breakpointsValues.lg]: {
            allowTouchMove: false,
          },
        },
      };
    }

    if (variant === BANNER) {
      return {
        slidesPerView: 1,
        loop: true,
        pagination: {
          clickable: true,
          el: `#pagination-${sliderId}`,
        },
        navigation: true,
        breakpoints: {
          [breakpointsValues.lg]: {
            allowTouchMove: true,
          },
        },
      };
    }

    return {
      slidesPerView: 1,
      centerInsufficientSlides: true,
      pagination: {
        clickable: true,
        el: `#pagination-${sliderId}`,
      },
      navigation: {
        nextEl: `#arrow-next-${sliderId}`,
        prevEl: `#arrow-prev-${sliderId}`,
      },
      loop: false,
      breakpoints: {
        [breakpointsValues.md]: {
          slidesPerView: 2,
          pagination: {
            clickable: true,
            el: `#pagination-${sliderId}`,
          },
        },
        [breakpointsValues.xl]: {
          slidesPerView: 3,
          pagination: {
            clickable: true,
            el: `#pagination-${sliderId}`,
          },
        },
      },
    };
  };

  return (
    <Swiper
      id={sliderId}
      tag="section"
      data-testid="swiper-slider"
      className={sliderClasses}
      onBreakpoint={(swiper, breakpointParams) => {
        if (breakpointParams.slidesPerView) {
          if (breakpointParams.slidesPerView >= swiper.wrapperEl.childElementCount) {
            setDisplayPagination(false);
            // eslint-disable-next-line no-param-reassign
            swiper.allowTouchMove = false;
          } else {
            setDisplayPagination(true);
            // eslint-disable-next-line no-param-reassign
            swiper.allowTouchMove = true;
          }
        }
      }}
      grabCursor
      onSlideChange={() => (setIsChanged ? toggleState(setIsChanged) : null)}
      {...swiperParams()}
    >
      {slides?.map((slide) => (
        <SwiperSlide key={slide.id}>{slide.component}</SwiperSlide>
      ))}
      {!isVideoSlider ? (
        <div className={paginationClassNames}>
          <div
            id={`arrow-prev-${sliderId}`}
            className={`swiper-button-prev swiper-button-prev--${sliderId}`}
          />
          <div
            id={`pagination-${sliderId}`}
            className={`swiper-pagination swiper-pagination--${sliderId}`}
          />
          <div
            id={`arrow-next-${sliderId}`}
            className={`swiper-button-next swiper-button-next--${sliderId}`}
          />
        </div>
      ) : null}
    </Swiper>
  );
};

export default Slider;
