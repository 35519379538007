import React from 'react';

export const ChallengeShape = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="155.574"
    height="108.092"
    viewBox="0 0 155.574 108.092"
  >
    <g transform="translate(-337.215 -609.179)">
      <path
        d="M18139.488,9250.181c19.156,25.188-.029-58.105-46.473-72.07s-71.283-10.207-91.9-25.874,9.463,48.763,36.15,63.667S18120.334,9224.992,18139.488,9250.181Z"
        transform="translate(-17655.563 -8539.126)"
        fill="currentColor"
        strokeWidth="3"
      />
    </g>
  </svg>
);
