import { tagMocks } from 'shared/mocks';

import { ImageDefaultPropsMock, ImageFilePropsMock, ImageStructureMock } from 'common/Image';

import { LinkButtonMock } from '../../Button';

const featureMock = {
  image: {
    imageStructure: ImageFilePropsMock,
  },
  title: 'feature-title',
  text: 'feature-description',
};

export const CardDefaultClassesMock = ['card', 'card--article', 'test-class'];

export const CardDefaultPropsMock = {
  title: 'test-title',
  image: ImageStructureMock,
  url: '/test-url',
  description:
    'Our powerful plant-based and hypoallergenic formula removes tough stains even in cold water. Made without dyes and brighteners!',
  className: 'test-class',
  variant: 'article',
  showBadge: true,
};

export const CardRecipePropsMock = {
  title: 'test-title',
  image: ImageStructureMock,
  url: '/test-url',
  label: 'test-label',
  preparingTime: '10 min',
  amount: '9.7g',
  nutritionalValues: 'fibre per portion',
  variant: 'recipe',
  showBadge: true,
};

export const CardProductPropsMock = {
  title: 'test-title',
  image: {
    imageStructure: ImageDefaultPropsMock,
  },
  features: [featureMock, featureMock, featureMock],
  buttons: [LinkButtonMock, LinkButtonMock],
  variant: 'product',
  color: 'white',
  url: '/test-url',
  showBadge: true,
};

export const CardMock = {
  id: '31a6ff15-f42f-5f55-98a5-061b65f93a6f',
  card: {
    title: 'Senokot Max Strenght Tablets',
    amount: '8g',
    preparingTime: '10min',
    nutritionalValues: '10',
    description:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>',
    variant: 'product',
    color: 'lime',
    buttons: [LinkButtonMock, LinkButtonMock],
    features: [featureMock, featureMock, featureMock],
    image: ImageStructureMock,
    showBadge: true,
  },
  tags: [tagMocks],
  url: '/our-products/senokot-max-strength-tablets/',
};
