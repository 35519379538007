import { LinkButtonMock } from 'components/Button';
import { CardMock } from 'components/Card';
import { SliderVariant } from 'components/Slider';

import { CarouselVariant } from '../models.d';

export const CarouselDefaultClassesMock = ['carousel', 'test-class'];

const { LIGHT } = CarouselVariant;
const { PRODUCTS } = SliderVariant;

export const CarouselDefaultPropsMock = {
  className: 'test-class',
  carousel: {
    title: 'Useful Advice',
    button: LinkButtonMock,
    variant: LIGHT,
  },
  carouselItems: [
    CardMock,
    { ...CardMock, id: '1', url: '/understand-your-gut/article-detail-page-1/' },
    { ...CardMock, id: '2', url: '/understand-your-gut/article-detail-page-2/' },
    { ...CardMock, id: '3', url: '/understand-your-gut/article-detail-page-3/' },
  ],
  carouselVariant: PRODUCTS,
};

export const CarouselChildrenMock = 'test content';
