import React, { FC } from 'react';
import classNames from 'classnames';
import FadeIn, { FADEIN } from 'framers/FadeIn';

import { Container } from 'layout';
import Typography from 'common/Typography';
import Button from 'components/Button';
import Card from 'components/Card';
import Slider from 'components/Slider';

import { CarouselMappedVariantType, CarouselProps } from './models.d';

import './Carousel.scss';

const { DOWN } = FADEIN;

const Carousel: FC<CarouselProps> = ({
  className,
  carouselItems,
  carousel,
  carouselVariant,
  sliderId,
}) => {
  const carouselProps: Partial<CarouselMappedVariantType> = {
    light: {
      backgroundColor: 'white',
      titleColor: 'sherwood-green',
    },
    dark: {
      backgroundColor: 'sherwood-green',
      titleColor: 'white',
    },
    blue: {
      backgroundColor: 'lochmara',
      titleColor: 'white',
    },
  };
  const carouselClasses = classNames(
    'carousel',
    `carousel--${carousel.variant}`,
    {
      [`background--${carouselProps[carousel.variant]?.backgroundColor}`]: carousel.variant,
    },
    className
  );

  return (
    <div className={carouselClasses} data-testid="carousel-item" id={sliderId}>
      <Container>
        {carousel.title ? (
          <Typography
            as="h2"
            align="center"
            color={carouselProps[carousel.variant]?.titleColor}
            size={{ base: 25, md: 40 }}
            padding={{ bottom: { base: 'sm', md: 'xs' } }}
            className="carousel__heading"
            animationVariant={DOWN}
          >
            {carousel.title}
          </Typography>
        ) : null}
        {carouselItems?.length ? (
          <FadeIn>
            <Slider
              slides={carouselItems.map(({ id, card, url }) => ({
                id,
                component: <Card {...card} url={url} variant={card.variant} />,
              }))}
              variant={carouselVariant}
              sliderId={sliderId || carouselVariant}
            />
          </FadeIn>
        ) : null}
        {carousel.button ? (
          <Button className="carousel__button" {...carousel.button}>
            {carousel.button.label}
          </Button>
        ) : null}
      </Container>
    </div>
  );
};

export default Carousel;
